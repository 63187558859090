 @import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;700&family=Montserrat:wght@400;700&display=swap');

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size:20px;
    line-height:28px;
}

h1,h2 {
    font-family: 'Shippori Mincho', serif;
    font-weight:normal;
}
