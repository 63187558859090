.login {
    display: flex;
    width:100%;
    align-items:center;
    justify-content:center;  
    margin-top: 40px;  
}

.login form.login-form {
    display:flex;
    flex-direction:column;
}

.login .labels {
    display:flex;
    justify-content:space-between;
    margin:10px 0;
}

.login label {
    color:#fff;
    margin:10px 0;
}

.login input,
.login textarea {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: inset 0 0 4px -2px #1c2222;
    margin:10px 0;
}

.login input:active,
.login textarea:active {
    border:1px solid #101414;
}

.login button {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    margin: 0 8px;
    box-shadow: 0 0 4px -2px #1c2222;
}

.login button:hover {
    background-color:#2b3535;
    cursor: pointer;
}

.login label.error {
    color:#fa5d5d;
    text-align: right;
}