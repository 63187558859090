.loading {
    display: flex;
    width: 100%;     
    height: 100%;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;left:0;
    z-index: 6;
}

.loading.overlay {
    background-color:#000a
}

.loading .loader {
    display:flex;
    position: relative;
}

.loading .loader.backer {
    position: absolute;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.loading .icon {
    font-size:36px;
    color:#d0d3d3;
    z-index: 4;
}

.loading .label {
    color:#d0d3d3;
    z-index: 4;
}

.loading .label h4 {
    font-size:28px;
    margin:10px 0;
}
