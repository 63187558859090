.about {
    display:flex;
    justify-content:center;
    position:relative;
    width: 100%;
}


.about.loading {
    width:100%;
    height:100%;
    overflow:hidden;
}

.about .photo {
    position:sticky;
    top:0;
    max-height:100vh;
    min-height:100vh;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:0 15px 0px 45px;
}

.about .photo-holder {
    width:200px;
    height:200px;
    border-radius:50%;
    overflow:hidden;
    background-color: #191f1f;
    display:flex;
    align-items:center;
    justify-content:center;
    position: relative;
    box-shadow: 0px 0px 4px -2px #0b0c0c;    
}



.about .photo-holder img {
    width:200px;
    text-align: center;
    color:#fff;
}
.about .photo-holder .photo-actions {
    position: absolute;
    bottom:0;
    width: 100%;
    display:flex;    
    z-index: 7;
}

.about .photo .photo-holder button.edit-image {        
    height:48px;
    flex:1;
    background-color:#effcfcad;
    border:none;
    color:#191f1f;
    padding: 0 20px;
}

.about .photo .photo-holder button.edit-image:first-child {        
    text-align: right;
}

.about .photo .photo-holder button.edit-image:nth-child(2) {     
    text-align: left;
}

.about .photo .photo-holder button.edit-image.single {
    text-align:center;
}

.about .photo .photo-holder button.edit-image:hover {
    cursor: pointer;
    background-color:#191f1f;
    color:#fff;
}

.about .photo-holder button.edit-image.cancel-edit {
    position: absolute;
    top: 0px;
    height: 38px;
    width:100%;        
    text-align: center; 
    display:flex;
    justify-content:center;
    align-items:center;
}


.about .all-of-the-images.on {
    position: relative;
    width:100%;
    left:0;
}

.about .about-content-wrapper {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.about .about-content-wrapper .about-content {
    position:relative;
    display:flex;
    width: 500px;
    flex-direction:column;
    justify-content:center;    
    background-color:#fff;
    color:#040410;
    padding:36px;
    margin:30px;
    z-index:0;
    -webkit-box-shadow: 0px 0px 4px -2px #250f03; 
    box-shadow: 0px 0px 4px -2px #250f03;
}

.about .about-content .content-actions {
    display:flex;
    position:absolute;
    z-index: 5;
    top: 0;
    right: 0;    
    -webkit-box-shadow: 0px 0px 4px -2px #94918f;
    box-shadow: 0px 0px 4px -2px #94918f;
}

.about .about-content .content-actions button.content-action {
    background-color: rgb(199, 206, 223);
    border:none;
    padding:13px;
    border-radius:0 0px 0 4px;
}

.about .about-content .content-actions button.content-action:nth-child(2) {
    border-radius:0;
    border-left: 1px solid rgb(63, 80, 119);
}

.about .about-content .content-actions button.content-action:hover {
    background-color: rgb(101, 120, 172);
    color:#fff;
    cursor: pointer;
}

.about .about-content .content textarea {
    width: 100%;
    min-height: 300px;
    border-radius:2px;
    background-color:rgb(231, 231, 231);
    border: 1px solid rgb(184, 176, 176);
}

@keyframes cornerBorders {
  0% {
      border-radius:0;
  }
  100% {
      border-radius:136px;
  }
}

.background-border-holder {    
    width:100%;
    height:100%;
    z-index:-1;
    padding:16px;
    box-sizing:border-box;
    position:absolute;
    top:0;
    left:0;
}

.background-border {
    width:100%;
    height:100%;
    display:block;
    box-sizing:border-box;
    border:1px solid #261101;
}

.background {
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    top:0;
    left:0;
    box-sizing:border-box;
    background-color:#fff;
    animation: 0.9s ease-out 0s 1 cornerBorders;
    border-radius:136px;
    z-index:0
}

.about .about-content .the-content {
    z-index:1;
}

.about .header {
    color: #fff;
    border-bottom: 1px solid #fff;
}
.about .header span {
    width:120px;
    display:block;
    border-bottom: 1px dashed rgb(60, 61, 61);
}

.about .inputs {
    display:flex;
    flex-direction:column;
}

.about .inputs label {
    color:#fff;
    margin:18px 0;
}
.about .inputs input[type=range] {
    -webkit-appearance: none; 
    width: 100%; 
    background: transparent;
}

.about .inputs input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.about .inputs input[type=range]:focus {
    outline: none;
}

.about .inputs input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;    
    height: 36px;
    width: 16px;
    border-radius: 4px;
    background: #e1e3e9;
    cursor: pointer;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 0px 0px 4px -2px #0d0d0d;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 4px -2px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 4px;
    background: #e1e3e9;
    cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 4px -2px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 4px;
    background: #e1e3e9;
    cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: inset 0 0 4px -2px #0d0d0d;
    background: #191f1f;
    border-radius: 1.3px;
  }
  
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #191f1f;
  }
  
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: inset 0 0 4px -2px #0d0d0d;
    background: #191f1f;
    border-radius: 1.3px;
  }

.about .header h1 {
    margin:0px 0px 15px 0px;
    padding:0;
    font-size:28px;
}

.about .content {
}

.about .content p {
    margin:10px 0;
    font-size:16px;
    padding:0;
}

@media (max-width:1180px) {
    .about {
        flex-direction:column;
        min-height: 100vh;
        padding:20px;
        box-sizing: border-box;
    }

    .about .about-content-wrapper .about-content {
        width:100%;
        margin:0;
        box-sizing: border-box;
    }
    .about .photo {
        position:relative;
        max-height: auto;
        min-height:auto;
        margin:20px 0;
        padding:0;
    }

    .about .about-content-wrapper .header h1 {
        text-align: center;
        font-size: 22px;        
    }
}