

.all-images img {
    object-fit:contain;
    max-width:234px;
    max-height:217px;    
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#0e1414;
    color:#fff;
}

.all-images .new-image {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width: 100%;

}


.all-images .image-loading {
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height: 100%;
}



.all-images .new-image input[type="file"] {
    width:230px;
    margin:20px;
    background-color:#121818
}

.all-images .new-image input:not([type="file"]):active {
    border:2px solid rgb(255, 255, 255);
}

.all-images .new-image input:not([type="file"]) {
    background-color:rgb(23, 24, 29);    
    border:2px solid rgb(23, 24, 29);
    box-shadow:inset 0 0 4px -2px #0008;
    color:#fff;    
    padding:8px;
    margin:10px;
}

.all-images .new-image label {
    color:rgb(255, 255, 255);
    width:220px;
    text-align:left;
}

.all-images .new-image label.error {
    color:rgb(231, 187, 187);
    margin:9px 0;
}



.all-images .new-image button {
    background-color:rgb(23, 24, 29);    
    border:2px solid rgb(23, 24, 29);
    box-shadow: 0 0 4px -2px #0008;
    color:#fff;
    padding:10px;
    margin:10px;    
    border-radius:2px;
    box-sizing: border-box;

}

.all-images .new-image button:hover {
    border:2px solid #fff;
}


.all-images button {
    border:3px solid #fff;
    max-width:234px;
    max-height:222px;
    box-sizing:border-box;
    display:block;
    margin:10px;
    background:none;
    padding:0;
    position: relative;
    
}

.all-images button:hover {
    cursor: pointer;
}
.all-images button:hover .action-message {
    display:flex;
}

.all-images button .action-message {
    display:none;
    position:absolute;
    flex-direction: column;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#343434ad;
    justify-content:center;
    align-items:center;
}

.all-images .action-message p {
    display:block;
    color:#fff;
    margin:0;
}

.actions-on-portfolio .actions-buttons {
    display:flex;
    justify-content:space-between;
    width:100%;
}


.actions-on-portfolio .actions-buttons button {
    display: block;
    padding: 0;
    background:none;
    border: none;
    font-size:22px;
    color: #dddeeb;
}


.actions-on-portfolio .actions-buttons button:hover   {
    cursor: pointer;
    color: #587474;
}

.all-images {
    display: flex;
    justify-content:center;
    width: 100%; 
    height:100%;
    position: relative;   
    flex-wrap: wrap;
    overflow-y: scroll;
    color:#fff;
}

.actions-on-portfolio {
    width: 300px;
    height:100%;
    background-color:#191f1f;
    padding:10px;
    position: fixed;
    bottom:0;
    left:0;
    box-sizing: border-box;
    z-index: 11;
}

.actions-on-portfolio.show {  

    height:100%;
    width:100%;    
}

.actions-on-portfolio.show .all-images {
    flex-wrap: wrap;
    justify-content:center;
}