.image-admin {
     display:flex;
     flex-wrap: wrap;
     justify-content:center;
     align-items:center;
}

.image-admin .image-header {
     width: 100%;
     display: flex;
     justify-content:space-between;
     align-items:center;
     padding:15px;
     background-color:rgb(237, 238, 238);
}

.image-admin .image-header p,
.image-admin .image-header a {
     padding:0;
     margin:0;
}

.image-admin .image-header a {
     color:#185757;
     text-decoration:none;
}

.image-admin .image-header a:hover {
     color:#294646;
}

.empty {
     display:flex;
     flex-direction: column;
     align-items:center;
     justify-content:center;
     padding:20px;
}

.empty p {
     color: #fff;
     font-size:18px;
     display: block;
}

.empty a {
     background-color:#455e5e;
     color: #fff;
     padding: 13px;
     display: block;
     text-decoration: none;
}

.empty a:hover {
     background-color: #668383;
     cursor: pointer;
}
