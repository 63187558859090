.image {
     
}

button.portfolio-action.float {
    position: absolute;
    left:-10px;
    top: -10px;
}

button.portfolio-action {
    background-color: #fff;
    color: #eb2525;
    border:none;
    padding: 5px;
    box-shadow: 0 0 2px 1px #353333;
    display:flex;
    border-radius:20px;
    font-size:20px;
}

button.portfolio-action:hover {
    cursor: pointer;
    background-color: #eb2525;
    color:#fff;
}


button.portfolio-actiony {
    background-color: #fff;
    border:none;
    padding: 5px;
    box-shadow: 0 0 2px 1px #353333;
    display:flex;
    border-radius:20px;
    font-size:20px;
    margin:20px;
}

button.portfolio-actiony:hover {
    cursor: pointer;
    background-color: #eb2525;
    color:#fff;
}


button.portfolio-action svg {
    display: inline-block;
}

.portfolio-remove-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    position:absolute;
    background-color:#333333ad;
    width:100%;
    height:100%;
}

.portfolio-remove-actions {
    display: flex;
    justify-content:center;
    width:100%;
}

.portfolio-remove-action h3 {
    color: #fff;text-align: center;
}

.portfolio-remove-action button.portfolio-action {
    display: block;
}