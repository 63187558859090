@import url(https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;700&family=Montserrat:wght@400;700&display=swap);
.navigation {
    background-color:#FFFFFF;
    padding:20px 50px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-align-items:flex-start;
            align-items:flex-start;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    min-height:100%;
    height:auto;
    box-sizing:border-box;
    width:300px;
    z-index:10; 
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
    transition:width 0.6s;
}

.navigation button.link {
    background:none;
    border:none;
    color:#000;
    font-size:18px;
}

.navigation.collapsed {
    width:50px;
    padding:20px;
    -webkit-align-items:center;
            align-items:center;
}

.navigation.open {
    position: fixed !important;
    top:0;
    left:0;
    width: 100vw;
}

.navigation button.close:hover {
    cursor: pointer;
}

.navigation button.close {
    position: absolute;
    top:0;
    right:0;
    padding:35px;
    background:none;
    border:none;
    color:#000;
}

.navigation.collapsed .links {
    -webkit-align-self:center;
            align-self:center;
}

.navigation.collapsed .links ul.nav-list li a span {
    opacity:0;
    width:0;
    -webkit-transform:scale(0);
            transform:scale(0);
}

.navigation.collapsed .logo {
    width:20px;    
    height:42px
}

.navigation.collapsed .links ul li {
    margin:10px 0;
}

.navigation.collapsed .links ul li a {
    padding:0;
}

.navigation.collapsed .links ul li a svg {
    margin:0;
}

.navigation.collapsed .social ul.social-list {
    -webkit-flex-direction:column;
            flex-direction:column;
}

.navigation.collapsed .social ul.social-list li {
    margin:6px 0;
}

.navigation.collapsed .social ul.social-list li a,
.navigation.collapsed .social ul.social-list li button {
    padding:0;    
}

.navigation .logo {
    width:200px;
    transition:width 0.6s ease, height 0.6s ease;
    padding:0;
}

.navigation .logo img {
    max-width:100%;
    max-height: 42px;
}

.navigation .links {
    -webkit-align-self:flex-start;
            align-self:flex-start;
}

.navigation .links ul {
    list-style:none;
    margin:0;
    padding:0;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
}

.navigation .links ul li {
    padding:0;
    margin:5px;
}

.navigation .links ul li a {
    text-decoration:none;
    position:relative;
    padding:5px;
    color:#111;
    font-size:16px;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
}

.navigation .links ul li a span {
    position:relative;
    display:block;
    opacity:1;
    -webkit-transform:scale(1);
            transform:scale(1);
    transition: opacity 0.3s ease, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, opacity 0.3s ease;
    transition: transform 0.3s ease-out, opacity 0.3s ease, -webkit-transform 0.3s ease-out;
}

.navigation .links ul li a svg {
    font-size:22px;
    margin:0 15px;
    display:block;
}

.navigation .links ul li a span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}

.navigation .links ul li a.active svg,
.navigation .links ul li a:hover svg {
    color: #0a6868;
}

.navigation .links ul li a.active span::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.navigation .links ul li a:hover span::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.navigation .social ul.social-list {
    list-style:none;
    margin:0;
    padding:0;
    display:-webkit-flex;
    display:flex;
}

.navigation .social ul.social-list li a,
.navigation .social ul.social-list li button {
    font-size:18px;
    padding:0 10px;
    display:block;
    background:none;
    border:none;
    color:#000;
}

.navigation .social ul.social-list li a:hover,
.navigation .social ul.social-list li button:hover {
    cursor: pointer;
    color:rgb(37, 103, 202);
}

.loading {
    display: -webkit-flex;
    display: flex;
    width: 100%;     
    height: 100%;
    overflow: hidden;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    top: 0;left:0;
    z-index: 6;
}

.loading.overlay {
    background-color:#000a
}

.loading .loader {
    display:-webkit-flex;
    display:flex;
    position: relative;
}

.loading .loader.backer {
    position: absolute;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;    
}

.loading .icon {
    font-size:36px;
    color:#d0d3d3;
    z-index: 4;
}

.loading .label {
    color:#d0d3d3;
    z-index: 4;
}

.loading .label h4 {
    font-size:28px;
    margin:10px 0;
}



.all-images img {
    object-fit:contain;
    max-width:234px;
    max-height:217px;    
    width:100%;
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    background-color:#0e1414;
    color:#fff;
}

.all-images .new-image {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    width: 100%;

}


.all-images .image-loading {
    position:absolute;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    width:100%;
    height: 100%;
}



.all-images .new-image input[type="file"] {
    width:230px;
    margin:20px;
    background-color:#121818
}

.all-images .new-image input:not([type="file"]):active {
    border:2px solid rgb(255, 255, 255);
}

.all-images .new-image input:not([type="file"]) {
    background-color:rgb(23, 24, 29);    
    border:2px solid rgb(23, 24, 29);
    box-shadow:inset 0 0 4px -2px #0008;
    color:#fff;    
    padding:8px;
    margin:10px;
}

.all-images .new-image label {
    color:rgb(255, 255, 255);
    width:220px;
    text-align:left;
}

.all-images .new-image label.error {
    color:rgb(231, 187, 187);
    margin:9px 0;
}



.all-images .new-image button {
    background-color:rgb(23, 24, 29);    
    border:2px solid rgb(23, 24, 29);
    box-shadow: 0 0 4px -2px #0008;
    color:#fff;
    padding:10px;
    margin:10px;    
    border-radius:2px;
    box-sizing: border-box;

}

.all-images .new-image button:hover {
    border:2px solid #fff;
}


.all-images button {
    border:3px solid #fff;
    max-width:234px;
    max-height:222px;
    box-sizing:border-box;
    display:block;
    margin:10px;
    background:none;
    padding:0;
    position: relative;
    
}

.all-images button:hover {
    cursor: pointer;
}
.all-images button:hover .action-message {
    display:-webkit-flex;
    display:flex;
}

.all-images button .action-message {
    display:none;
    position:absolute;
    -webkit-flex-direction: column;
            flex-direction: column;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#343434ad;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.all-images .action-message p {
    display:block;
    color:#fff;
    margin:0;
}

.actions-on-portfolio .actions-buttons {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    width:100%;
}


.actions-on-portfolio .actions-buttons button {
    display: block;
    padding: 0;
    background:none;
    border: none;
    font-size:22px;
    color: #dddeeb;
}


.actions-on-portfolio .actions-buttons button:hover   {
    cursor: pointer;
    color: #587474;
}

.all-images {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    width: 100%; 
    height:100%;
    position: relative;   
    -webkit-flex-wrap: wrap;   
            flex-wrap: wrap;
    overflow-y: scroll;
    color:#fff;
}

.actions-on-portfolio {
    width: 300px;
    height:100%;
    background-color:#191f1f;
    padding:10px;
    position: fixed;
    bottom:0;
    left:0;
    box-sizing: border-box;
    z-index: 11;
}

.actions-on-portfolio.show {  

    height:100%;
    width:100%;    
}

.actions-on-portfolio.show .all-images {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content:center;
            justify-content:center;
}
.about {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    position:relative;
    width: 100%;
}


.about.loading {
    width:100%;
    height:100%;
    overflow:hidden;
}

.about .photo {
    position:-webkit-sticky;
    position:sticky;
    top:0;
    max-height:100vh;
    min-height:100vh;
    box-sizing:border-box;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    padding:0 15px 0px 45px;
}

.about .photo-holder {
    width:200px;
    height:200px;
    border-radius:50%;
    overflow:hidden;
    background-color: #191f1f;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    position: relative;
    box-shadow: 0px 0px 4px -2px #0b0c0c;    
}



.about .photo-holder img {
    width:200px;
    text-align: center;
    color:#fff;
}
.about .photo-holder .photo-actions {
    position: absolute;
    bottom:0;
    width: 100%;
    display:-webkit-flex;
    display:flex;    
    z-index: 7;
}

.about .photo .photo-holder button.edit-image {        
    height:48px;
    -webkit-flex:1 1;
            flex:1 1;
    background-color:#effcfcad;
    border:none;
    color:#191f1f;
    padding: 0 20px;
}

.about .photo .photo-holder button.edit-image:first-child {        
    text-align: right;
}

.about .photo .photo-holder button.edit-image:nth-child(2) {     
    text-align: left;
}

.about .photo .photo-holder button.edit-image.single {
    text-align:center;
}

.about .photo .photo-holder button.edit-image:hover {
    cursor: pointer;
    background-color:#191f1f;
    color:#fff;
}

.about .photo-holder button.edit-image.cancel-edit {
    position: absolute;
    top: 0px;
    height: 38px;
    width:100%;        
    text-align: center; 
    display:-webkit-flex; 
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}


.about .all-of-the-images.on {
    position: relative;
    width:100%;
    left:0;
}

.about .about-content-wrapper {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.about .about-content-wrapper .about-content {
    position:relative;
    display:-webkit-flex;
    display:flex;
    width: 500px;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;    
    background-color:#fff;
    color:#040410;
    padding:36px;
    margin:30px;
    z-index:0; 
    box-shadow: 0px 0px 4px -2px #250f03;
}

.about .about-content .content-actions {
    display:-webkit-flex;
    display:flex;
    position:absolute;
    z-index: 5;
    top: 0;
    right: 0;
    box-shadow: 0px 0px 4px -2px #94918f;
}

.about .about-content .content-actions button.content-action {
    background-color: rgb(199, 206, 223);
    border:none;
    padding:13px;
    border-radius:0 0px 0 4px;
}

.about .about-content .content-actions button.content-action:nth-child(2) {
    border-radius:0;
    border-left: 1px solid rgb(63, 80, 119);
}

.about .about-content .content-actions button.content-action:hover {
    background-color: rgb(101, 120, 172);
    color:#fff;
    cursor: pointer;
}

.about .about-content .content textarea {
    width: 100%;
    min-height: 300px;
    border-radius:2px;
    background-color:rgb(231, 231, 231);
    border: 1px solid rgb(184, 176, 176);
}

@-webkit-keyframes cornerBorders {
  0% {
      border-radius:0;
  }
  100% {
      border-radius:136px;
  }
}

@keyframes cornerBorders {
  0% {
      border-radius:0;
  }
  100% {
      border-radius:136px;
  }
}

.background-border-holder {    
    width:100%;
    height:100%;
    z-index:-1;
    padding:16px;
    box-sizing:border-box;
    position:absolute;
    top:0;
    left:0;
}

.background-border {
    width:100%;
    height:100%;
    display:block;
    box-sizing:border-box;
    border:1px solid #261101;
}

.background {
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    top:0;
    left:0;
    box-sizing:border-box;
    background-color:#fff;
    -webkit-animation: 0.9s ease-out 0s 1 cornerBorders;
            animation: 0.9s ease-out 0s 1 cornerBorders;
    border-radius:136px;
    z-index:0
}

.about .about-content .the-content {
    z-index:1;
}

.about .header {
    color: #fff;
    border-bottom: 1px solid #fff;
}
.about .header span {
    width:120px;
    display:block;
    border-bottom: 1px dashed rgb(60, 61, 61);
}

.about .inputs {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
}

.about .inputs label {
    color:#fff;
    margin:18px 0;
}
.about .inputs input[type=range] {
    -webkit-appearance: none; 
    width: 100%; 
    background: transparent;
}

.about .inputs input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.about .inputs input[type=range]:focus {
    outline: none;
}

.about .inputs input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;    
    height: 36px;
    width: 16px;
    border-radius: 4px;
    background: #e1e3e9;
    cursor: pointer;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 0px 0px 4px -2px #0d0d0d;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 4px -2px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 4px;
    background: #e1e3e9;
    cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 4px -2px #0d0d0d;
    height: 36px;
    width: 16px;
    border-radius: 4px;
    background: #e1e3e9;
    cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: inset 0 0 4px -2px #0d0d0d;
    background: #191f1f;
    border-radius: 1.3px;
  }
  
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #191f1f;
  }
  
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: inset 0 0 4px -2px #0d0d0d;
    background: #191f1f;
    border-radius: 1.3px;
  }

.about .header h1 {
    margin:0px 0px 15px 0px;
    padding:0;
    font-size:28px;
}

.about .content {
}

.about .content p {
    margin:10px 0;
    font-size:16px;
    padding:0;
}

@media (max-width:1180px) {
    .about {
        -webkit-flex-direction:column;
                flex-direction:column;
        min-height: 100vh;
        padding:20px;
        box-sizing: border-box;
    }

    .about .about-content-wrapper .about-content {
        width:100%;
        margin:0;
        box-sizing: border-box;
    }
    .about .photo {
        position:relative;
        max-height: auto;
        min-height:auto;
        margin:20px 0;
        padding:0;
    }

    .about .about-content-wrapper .header h1 {
        text-align: center;
        font-size: 22px;        
    }
}
.media-slides {
    display:-webkit-flex;
    display:flex;
    position:relative;
}

.media-slides .media {
    position:absolute;
    object-fit:cover;
    object-position:10%;
    margin:0 auto;
    width:100%;
    height:100%;
}

.loading-images {
    height:400px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.experience {
    background-color:#fffdfd;
    padding:5px;
    margin:10px;
    box-sizing:content-box; 
    box-shadow: 0px 0px 2px -1px #250f03;
    border-radius:5px;
    width:300px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
}

.experience .media-preview {
    width:300px;
    height:400px;
    overflow:hidden;
}

.experience .info {
    padding:5px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:space-between;
            justify-content:space-between;
}
.experience .info h3 {
    font-size:20px;
    margin:5px 0;
}

.experience .info p {
    font-size:14px;
    margin:0px 0;
    line-height:18px;
}

.experience .info .stats {
    display:-webkit-flex;
    display:flex;
    border-top:1px solid #250f03;
    padding:3px 0;
    margin-top:5px;
    box-sizing:border-box;
}

.experience .info .stats .stat {
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    padding: 3px 5px;
    padding-left:0;
}

.experience .info .stats .stat span {
    display:block;
    padding: 0 5px;
}

.experience .info .stats .stat svg {
    font-size:16px;
    color:#8f3821;
}

.experiences {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
}

.experiences .header {
    -webkit-align-self:flex-start;
            align-self:flex-start;
    margin:3px 25px;
}

.experiences .header h1 {
    margin:0;
    padding:0;
}

.experiences .content {
    display:-webkit-flex;
    display:flex;
}

.portfolio {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
            flex-wrap:wrap;
    -webkit-justify-content:space-around;
            justify-content:space-around;
    position: relative;
}

.portfolio.loading {
    width:100%;
    height:100%;
    overflow: hidden;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.portfolio .entry {
    width:400px;
    display:-webkit-flex;
    display:flex;
    position:relative;
    margin:45px 0;
    z-index:2;
}

.portfolio .hidden {
    display:none !important;
}

.portfolio .entry .image-wrapper {
    display:-webkit-flex;
    display:flex;
    width:400px;
    max-height:260px;    
    border:4px solid #FFF;
    box-sizing:border-box;
    position:relative;    
    overflow:hidden;
}

.portfolio .portfolio-title button.no-style:hover {
    background:none;
}

.portfolio .portfolio-title p {
    margin: 0 18px;
    font-size:15px;
    -webkit-flex:1 1;
            flex:1 1;
    text-align:right;
}

.portfolio .portfolio-title button.no-style {
    background:none;
    border:none;
    border-right: 2px solid rgb(129, 129, 129);
    color:#000;
    margin:10px;
    padding:0 5px 0 0px;
    border-radius:0;
}

.portfolio .entry .image-wrapper {
    opacity: 0;
}

.portfolio .entry .image-wrapper:first-child,
.portfolio .entry .image-wrapper:nth-child(3),
.portfolio .entry .image-wrapper:nth-child(2) {
    z-index:2;
    opacity: 1;
}

.portfolio .entry .image-wrapper:not(:first-child) {
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    transition:-webkit-transform 0.5s;
    transition:transform 0.5s;
    transition:transform 0.5s, -webkit-transform 0.5s;
}

.portfolio .entry:hover {
    cursor:pointer;
}
.portfolio .entry:hover .info p {
    display:block;
}

.portfolio .entry:hover .image-wrapper:nth-child(3) {
    -webkit-transform:rotate(7deg) translate(10px,20px);
            transform:rotate(7deg) translate(10px,20px);
}

.portfolio .entry:hover .image-wrapper:nth-child(2) {
    -webkit-transform:rotate(-8deg) translate(-10px,-20px);
            transform:rotate(-8deg) translate(-10px,-20px);
}

.portfolio .entry .image-wrapper a.image {
    width:100%;
    height:100%;
}

.portfolio .entry .image-wrapper a.image img {
    width:100%;
    height:100%;
    object-fit:cover;
    z-index:2;
    position:relative;
}

.portfolio .entry .info {
    position:absolute;
    top:0;
    left:5%;
    width:90%;
    height:100%;
    z-index:2;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.portfolio .entry .info h2 {
    color:#fff;
    margin:0;
}

.portfolio .entry .info-content {
    padding:20px 40px;;
    background-color:rgba(0,0,0,0.65);
    text-align:center;
}

.portfolio .entry .info p {
    color:#fff;
    padding:0;
    margin:0;
    font-size:14px;
    display:none;
}

.portfolio .portfolio-single {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
            flex-wrap:wrap;
    padding:20px;
    -webkit-justify-content:center;
            justify-content:center;
}

.portfolio-single .image-wrapper a.image:hover .info {
    display:-webkit-flex;
    display:flex;
    
}

.portfolio-single .info {
    display:none;
}
    
.portfolio-single .image-wrapper {
    max-width:400px;
    max-height:320px;
    margin:10px;
    position:relative;
    display:-webkit-flex;
    display:flex;
}

.portfolio-single .image-wrapper a.image {
    display:block;
    box-sizing: border-box;
    position: relative;
    border:4px solid #FFF;
}

.portfolio-single a.image .info {
    display:none;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    position:absolute;
    bottom:0;
    right:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.4);    
}

.portfolio-single a.image .info p {
    color:#fff;
    text-align:right;
    padding:0;
    margin:10px;
    font-size:16px;
}

.portfolio-single img {
    width:100%;
    height:100%;
    object-fit:cover;
    box-sizing:border-box;
}

.portfolio-slide {
    height:100vh;
    position:relative;
    display:-webkit-flex;
    display:flex;
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.portfolio-slide .control {
    min-width:50px;
    width: 50px;
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    position: absolute;
    top: 0;
    left:50px;
    z-index:5
}

.portfolio-slide .control:last-child {
    right:50px;
    left:unset;    
}

.portfolio-slide .control button:hover {
    cursor:pointer;
    color:#2b3535;
	
}
.portfolio-slide .control button {
    display:block;
    font-size:36px;
    color:#191f1f;
    border:none;
    background:none;
}

.portfolio-slide .title {
    position:absolute;
    background-color:#fff;
    top:0;
    left:-50px;
    display:-webkit-flex;
    display:flex;    
    -webkit-align-items:center;    
            align-items:center;
    z-index:5; 
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
}

.portfolio-slide .title a {
    width:50px;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    border-right:1px solid #000;    
}

.portfolio-slide .title a svg {
    font-size:18px;    
}

.portfolio-slide .title span svg {
    font-size:16px;
}

.portfolio-slide .title span {
    margin:19px;
    display:block;
    display:-webkit-flex;
    display:flex;
    font-size:20px;
    -webkit-align-items:center;
            align-items:center;
}


.portfolio-slide .image-holder {    
    height:100vh;
    position:absolute;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
}


.portfolio-slide .image-holder .image-slide img.iiz__img {
    object-fit:contain;
    max-height:100vh;
    overflow:hidden;
}


.fade-enter {
    -webkit-transform: scale(0);
            transform: scale(0);
}

.fade-enter.fade-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in;
    transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}

.portfolio .new-entry.on {
    background-color:#191f1f;
}


.portfolio .portfolio-title {
    background-color:#fff;
    width:100%;
    height:60px;
    padding:10px 40px;
    box-sizing: border-box;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
}

.portfolio input {
    padding:10px;
    border:none;
    background-color:rgb(223, 223, 223);
    border-radius: 3px;
    margin:0 5px 0 0;
}


.portfolio .portfolio-title button:hover {
    background-color:#3f4e4e;
    cursor: pointer;
}

.portfolio .portfolio-title button {
    border:none;
    background-color: #191f1f;
    color:#fff;
    border-radius: 3px;
    padding:10px;
    margin: 0 10px;
}

.portfolio .portfolio-title h4 {
    margin:0;    
}

.portfolio .new-entry {    
    width:400px;
    height:300px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    color:#fff;
}

.portfolio .new-entry button {
    background-color:#232b2b;
    margin: 10px;
    color: #fff;
    border:none;
    padding:8px 18px;
    border-radius:3px;
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
}

.portfolio .new-entry button:hover {
    cursor: pointer;
    background-color:#344141;
    
}
.portfolio .new-entry input {
    padding:10px;
    width:200px;
    background-color:#0e1313;
    border:none;
    color:#fff;
}



.portfolio .new-entry label {
    text-align:left;
    width:220px;
    margin: 10px;
}

.portfolio .new-entry.off button {
    background-color:#3f4e4e;
}

.portfolio .new-entry.off button:hover {
    background-color:#4b5e5e;
}

.portfolio .entry.empty {
    background-color: #191f1f;
    height:240px;
}



.portfolio .action-popper {
    position: absolute;
    top: 20px;
    right:20px;
}

.portfolio .actions-floater .deleter {
    width:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    background-color:#220a09;;
}

.portfolio .actions-floater {
    position: absolute;
    right:0px;
    top:0px;    
    width:150px;
    background-color: #191f1f;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:column;
            flex-direction:column;
}
.portfolio button.action-floater {
    margin:10px;
    border:none;
    padding:12px;
    background-color:#344141;
    color:#fff;
    border-radius:3px;
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
}

.portfolio button.action-floater:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

@media (max-width:827px) {
    .portfolio .entry {
        width:230px;
    }

    .portfolio .entry .image-wrapper {
        width:230px;
    }

    .portfolio .portfolio-title {
        padding:10px;
    }
}
.image {
     
}

button.portfolio-action.float {
    position: absolute;
    left:-10px;
    top: -10px;
}

button.portfolio-action {
    background-color: #fff;
    color: #eb2525;
    border:none;
    padding: 5px;
    box-shadow: 0 0 2px 1px #353333;
    display:-webkit-flex;
    display:flex;
    border-radius:20px;
    font-size:20px;
}

button.portfolio-action:hover {
    cursor: pointer;
    background-color: #eb2525;
    color:#fff;
}


button.portfolio-actiony {
    background-color: #fff;
    border:none;
    padding: 5px;
    box-shadow: 0 0 2px 1px #353333;
    display:-webkit-flex;
    display:flex;
    border-radius:20px;
    font-size:20px;
    margin:20px;
}

button.portfolio-actiony:hover {
    cursor: pointer;
    background-color: #eb2525;
    color:#fff;
}


button.portfolio-action svg {
    display: inline-block;
}

.portfolio-remove-action {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
    position:absolute;
    background-color:#333333ad;
    width:100%;
    height:100%;
}

.portfolio-remove-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    width:100%;
}

.portfolio-remove-action h3 {
    color: #fff;text-align: center;
}

.portfolio-remove-action button.portfolio-action {
    display: block;
}
.package-wrapper {
    width:100%;
    height:300px;
    overflow: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    overflow:unset;
    position: relative;
}

.edit-actions {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;    
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:space-around;
            justify-content:space-around;
    margin:12px 0;
}


.edit-actions.image-change {
    bottom:0;
    left:0;
    top:unset;
    width: 70px;
}

.edit-actions.sticky {
    margin:0;
    position:fixed;
    top:52.4px;
    z-index: 3;    
    width:auto;
    height:auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    -webkit-align-items:flex-end;
            align-items:flex-end;
}

.edit-actions button.edit-package {
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border:none;
    height:50px;
    width:50px;
    color:#fff;
    z-index:4;
    position: relative;
}

.edit-actions .edit-action-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.edit-actions .edit-action-group button.edit-package {
    width:auto;
}

.edit-actions button.edit-package.label span {
    margin-left:50px;
}


.package-wrapper .package-section .edit-actions .edit-action-group {
    -webkit-flex-direction:row;
            flex-direction:row;
}

.package-wrapper .package-section .edit-actions .edit-action-group button.edit-package {
    margin:0 20px;
}

.package-wrapper .package-section .confirm-delete {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color:#000d;
    z-index:3;
}

.package-wrapper .package-section .confirm-delete .confirm-delete-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;    
}   

.package-wrapper .package-section .confirm-delete .confirm-delete-actions button.confirm-delete-action:hover {
    cursor: pointer;
    background-color:rgb(79, 109, 175);
}
.package-wrapper .package-section .confirm-delete .confirm-delete-actions button.confirm-delete-action {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    background-color:rgb(53, 61, 97);
    border:none;
    color:#fff;
    width:140px;
    height:140px;
    border-radius:100px;
    margin:0 10px;
}

.package-wrapper .package-section .confirm-delete .confirm-delete-actions button.confirm-delete-action svg {
    font-size: 26px;
    margin:5px;
}

.edit-actions button.edit-package:hover svg:not(:only-child) {   
    border-right:3px solid #374642;
}

.edit-actions button.edit-package svg:not(:only-child) {   
    border-right:3px solid #51615d;
    width:50px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top:0;
    padding:10px;
    height:100%;
}

.edit-actions button.edit-package:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

.edit-actions button.edit-package.disabled {
    color:#818181;
}

.edit-actions button.edit-package.disabled:hover {
    cursor: default;
    background-color:#344141;
}

.package-section button.new-section:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

.package-section button.new-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:center;
            justify-content:center;
    font-size:20px;
    width:200px;
    height:200px;
    color:#fff;
    border-radius:100px;
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border: none;
}

.package-section button.new-section  svg {
    font-size: 36px;
}

.package-handle {
    width: 100%;
    height:100%;
    text-decoration: none;
}

.package-wrapper.entry .package-handle:hover {
    cursor: pointer;
}

.package {
    position:relative;
    width: 100%;
    height: 300px;
    display: -webkit-flex;
    display: flex;    
    -webkit-justify-content:center;    
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    -webkit-flex-direction:row;
            flex-direction:row;
    padding:50px;
    box-sizing: border-box;
    transition:height linear 500ms;
}

.package .package-info {    
    display: -webkit-flex;    
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    max-width: 500px;
    min-width: 300px;
    max-height: 230px;
    z-index: 2;
    padding:30px;
    color:#fff;
    box-sizing: border-box;
    position: relative;
}

.package .package-info h1 {
    font-size:26px;
}

.package .package-info input {    
    background-color: #0005;
    border:1px solid #1a1b29ee;
    color:#fff;
    padding:16px;
    font-size: 26px;
    font-family: 'Shippori Mincho', serif;
    box-sizing: border-box;    
}


.package .package-info button.package-info-action {
    position:absolute;
    top:-20px;
    right:-20px;
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border:none;
    width:40px;
    height:40px;
    color:#fff;
    border-radius:70px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    z-index: 5;
}

.package .package-info button.package-info-action:hover {    
    cursor: pointer;
    background-color:#4b5e5e;
}

.move-wrapper {    
    position:absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;    
}

button.move {        
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border:none;
    width:40px;
    height:40px;
    color:#fff;
    border-radius:70px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

button.move:hover {        
    cursor: pointer;
    background-color:#4b5e5e;
    color:rgb(3, 18, 54);
}

.move-wrapper.left {
    left: -50px;
    top:0;
}

.move-wrapper.right {
    right: -50px;
    top:0;
}

.package-wrapper .package-background .overlay {
    background-color:#191f1f;
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
}

.package-wrapper.single .package-background .overlay{
    display:none;
}

.package .explore {
    z-index: 2;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:center;
            justify-content:center;
    color: #fff;
    font-size: 24px;
    height: 100%;
    width: 50%;
}

.package-wrapper.single .package .explore {
    display: none;
}

.package .explore svg {
    font-size:38px;
}

.package-wrapper .package-background {
    position: absolute;
    overflow:hidden;
    width:100%;
    height:100%;
}
.package-wrapper.single {
    height:auto;
}

.package-wrapper.single .package {
    height:230px;
}

.package-wrapper .package-background img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.sun-editor {
    background-color: #0005;
    border:1px solid #1a1b29ee;
    box-sizing: border-box;
    margin-top: 16px;
}

.package-wrapper.edit .package .package-info {
    padding: 14px;
    max-height: 240px;
}

.package-wrapper.edit .package {
    height:300px;
}

.package-wrapper .contents {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.package-wrapper .contents .package-section {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:space-around;
            justify-content:space-around;
    height:500px;
    width: 100%;
    background-color:#232b2b;   
    color: #fff; 
    position: relative;
}

.package-wrapper .contents .package-section .divider {
    position: absolute;
    width: 100%;    
    height: 100%;
    top:0;
    left:0;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
}

.package-wrapper .contents .package-section.left {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding:40px;
    box-sizing: border-box;
}

.package-wrapper .contents .package-section.middle {
    -webkit-justify-content: center;
            justify-content: center;
    padding:40px;
    box-sizing: border-box;
}

.package-wrapper .contents .package-section.right {
    -webkit-justify-content:flex-end;
            justify-content:flex-end;
    padding:40px;
    box-sizing: border-box;
}

.package-wrapper .contents .package-section .actions-section {
    position:absolute;
    bottom:-65px;    
    left:0;
    width: 100%;
    display: -webkit-flex;
    display: flex;    
    -webkit-justify-content:space-around;    
            justify-content:space-around;     

}
.package-wrapper .contents .package-section .package-text .sun-editor {
    margin:0;
}

.package-wrapper .contents .package-section .package-text.with-background {
    background-color:rgba(50, 51, 65, 0.933);
    position: relative;
}

.package-wrapper .contents .package-section .image-background .actions-section {
    margin-bottom: 70px;
}

.package-wrapper .contents .package-section .actions-section .action-remove:hover {   
    background-color:#4b5e5e;
    cursor: pointer;
}

.package-wrapper .contents .package-section .actions-section .action-remove {   
    border:none;
    font-size: 24px;
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    color:#fff;
    width:60px;
    height:60px;
    border-radius: 120px;
}

.package-wrapper .contents .package-section .divider button.divider-switch:hover {
    color: #5b7575;
    cursor: pointer;
}

.package-wrapper .contents .package-section .divider button.divider-switch {
    position: absolute;
    background-color:#232b2b;
    border:none;
    color:#fff;
    font-size:42px;
}

.package-wrapper .contents .package-section .divider .divider-line {
    height: 60%;
    width: 1px;
    background-color:#344141;
}

.package-wrapper .contents .package-section:nth-child(odd) .divider button.divider-switch,
.package-wrapper .contents .package-section:nth-child(odd)   {
    background-color:#191f1f;
}

.package-wrapper .contents .package-section .package-text {
    padding:26px;
    max-width: 400px;
    max-height:400px;
    font-size:20px;
    line-height: 30px;
    z-index: 1;
    text-align:center;
    -webkit-flex:1 1;
            flex:1 1;
    position:relative;
}

.package-wrapper .remove-confirm-actions {
    display: -webkit-flex;
    display: flex;
}

.package-wrapper .remove-confirm-action svg {
    font-size:28px;
    margin:4px 0;
}

.package-wrapper .remove-confirm-action:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

.package-wrapper .remove-confirm-action {
    border:none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    background-color:#344141;
    color: #fff;
    width:120px;
    height:120px;
    border-radius:60px;
    margin:0 20px
}

.package-wrapper .remove-confirm {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    background-color:#222e;
    color:#fff;
    z-index:5;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    -webkit-flex-direction:column;
            flex-direction:column;
}
.package-wrapper .contents .package-section.reverse {
    -webkit-flex-direction:row-reverse;
            flex-direction:row-reverse;
}

.package-wrapper .contents .package-section .package-image {
    max-width: 500px;
    max-height: 400px;
    -webkit-flex:1 1;
            flex:1 1;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.package-wrapper .contents .package-section .package-image.image-background {
    position:absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;    
}

.package-wrapper .contents .package-section .package-image.image-background img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit:cover;
}

.package-wrapper .contents .package-section .package-image img {
    max-width: 500px;
    max-height:340px;    
    display: -webkit-flex;    
    display: flex;        
    object-fit: contain;
    box-shadow: 0 0 4px -1px #000;
}

.package-wrapper .contents .package-section .package-image.back {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    max-width: 100%;    
    z-index:0;
}

.package-wrapper .contents .package-section .package-image.back img {
    object-fit: cover;
    box-shadow: none;
}

@media (max-width:827px) {
    .package-wrapper .package-handle .filler {
        display:none !important;
    }

    .package .package-info {
        min-width: 200px;
        max-width: 260px;
    }

    .package-wrapper .contents .package-section {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .package-wrapper .contents .package-section .package-image {
        max-width: 300px;
    }

    .package-wrapper .contents .package-section .package-image img {
        max-width: 300px;
        width:300px;
        height:auto
    }
}
.fetch-image.loading {
     width:100%;
     display:-webkit-flex;
     display:flex;
     -webkit-justify-content:center;
             justify-content:center;
     -webkit-align-items:center;
             align-items:center;
     background-color:#191f1f;
}

.packages {
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content:center;
             justify-content:center;
     -webkit-align-items:center;
             align-items:center;
     -webkit-flex-direction: column;
             flex-direction: column;
     width:100%;
}

.packages.loading {
     height:100%;
     overflow:hidden;
}

.packages button.create-package {
     display:-webkit-flex;
     display:flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     border:none;
     background-color:#191f1f;
     color:#fff;
     width:160px;
     height:160px;
     margin:120px 0;
     -webkit-justify-content:center;
             justify-content:center;
     -webkit-align-items:center;
             align-items:center;     
     border-radius: 80px;
}

.packages button.create-package svg {
     font-size:26px;
     margin:10px 0;
}

.packages button.create-package:hover {
     cursor: pointer;
     background-color:#344141;
}
.login {
    display: -webkit-flex;
    display: flex;
    width:100%;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;  
    margin-top: 40px;  
}

.login form.login-form {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
}

.login .labels {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    margin:10px 0;
}

.login label {
    color:#fff;
    margin:10px 0;
}

.login input,
.login textarea {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: inset 0 0 4px -2px #1c2222;
    margin:10px 0;
}

.login input:active,
.login textarea:active {
    border:1px solid #101414;
}

.login button {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    margin: 0 8px;
    box-shadow: 0 0 4px -2px #1c2222;
}

.login button:hover {
    background-color:#2b3535;
    cursor: pointer;
}

.login label.error {
    color:#fa5d5d;
    text-align: right;
}
body {
    width:100%;
    height:100%;
}

#root {
    
}

.coming-soon {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:#fefefe;
    z-index:10;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    font-size:16px;
}

.coming-soon p {
    display:block;
}

.site-content {
    position:relative;
    display:-webkit-flex;
    display:flex;
    background-color: 	#232b2b;
}

.main-content {
    -webkit-flex:1 1;
            flex:1 1;
    position: relative;
}

.site-content .nav {
    position:-webkit-sticky;
    position:sticky;
    top:0;
    max-height:100vh;
    min-height:100vh;
}

.loading {
    width:100%;
    height:100%;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}
.image-editor {
    position: relative;
    margin:10px;    
    padding: 10px;    
    border-radius:3px;
    background-color:#191f1f;
}

.image-editor .error {
    position:absolute;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    border-radius:3px;
    background-color:rgb(33, 39, 54);
    z-index: 6;;
}

.CircularProgressbar-trail {
    display: none;
  }

.image-editor .error button.error-close {
    border:none;    
    display:block;
    padding:10px 20px;
    font-weight: bold;
    margin:7px;
    color:rgb(37, 37, 37);
    background-color:rgb(171, 179, 196);
    border-radius:2px;
}

.image-editor .error button.error-close:hover {
    background-color:rgb(219, 214, 214);
    color:#000;
    cursor: pointer;
}

.image-editor .error p {
    color:#fff;
}

.image-editor form .image { 
    display:-webkit-flex; 
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;    
}

.image-editor form .image .title { 
    display:-webkit-flex; 
    display:flex;
    -webkit-align-items:center;
            align-items:center;
}

.image-editor form .image .title input { 
    display:block;
    background-color:#0d1111;
    color:#fff;
    border:none;
    padding:10px;
    margin:10px;
}

.image-editor form .image .title label { 
    display:block;
    color:rgb(219, 224, 248);
}

.image-editor form .image input {
    display:block;
    width:200px;
    box-sizing: border-box;
}

.image-editor form .image p {
    display:block;
    width: 290px;
    padding:10px;
    margin:0;    
    color:rgb(220, 225, 248);
}

.image-editor form .image img {    
    max-width: 300px;
    min-width: 300px;
    max-height: 250px;
    min-height: 250px;
    background-color:#00000065;
    object-fit: contain;
}

.image-editor.regular:hover .actions {
    display:-webkit-flex;
    display:flex;
}

.image-editor .image-holder {
    position: relative;
}

.image-editor form .actions {
    position: absolute;
    width:100%;
    height:100%;
    background-color:#000000cc;
    display:none;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.image-editor form .actions button.action {
    width:48px;    
    height:48px;
    padding: 0px;
    border: none;
    color: #FFF;
    font-size: 18px;
    z-index: 3;
    border-radius:2px;
    top:170px;
}

.image-editor button.delete {
    background-color: rgb(219, 87, 87);
}

.image-editor button.submit {
    background-color:#344141 ;
}

.image-editor button.submit:hover {
    background-color: #4b5e5e;
    cursor: pointer;
}

.image-editor button.delete:hover {
    cursor:pointer;
    background-color:#4b5e5e;
}

.image-editor.confirm button.action {
    display:none;
}

.image-editor .confirm-delete {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:center;
            justify-content:center;
    position: absolute;
    width:100%;
    height:100%;
    background-color:#191f1f;    
    top: 0;
    color:#fff;
    left:0;
    border-radius:3px;
    -webkit-transform-origin: 60% 55%;
            transform-origin: 60% 55%; 
    z-index: 6;
}

.image-editor .confirm-delete .options {
    display: -webkit-flex;
    display: flex;
}

.image-editor .confirm-delete .options button:hover {
    background-color:rgb(219, 214, 214);
    color:#000;
    cursor: pointer;
}

.image-editor .confirm-delete .options button {
    border:none;    
    display:block;
    padding:10px 20px;
    font-weight: bold;
    margin:7px;
    color:rgb(37, 37, 37);
    background-color:rgb(171, 179, 196);
    border-radius:2px;
}

.image-editor .confirm-delete .options button:first-child:hover {
    background-color:rgb(219, 214, 214);
    color:rgb(179, 79, 79);
}
.image-editor .confirm-delete .options button:first-child {
    background-color:rgb(179, 79, 79);
    color:rgb(41, 1, 1);
}

.image-editor form .image-submit {
    display:block;
    width: 48px;

}

.image-editor label.error-label {
    color:rgb(228, 124, 124);
    margin:0px 0 10px 0px;
}

.image-editor .progress-circle-wrapper {
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    width: 100%;
    height:100%;
    position: absolute;
    border-radius:3px;
    top:0;
    left:0;
    z-index: 6;
    background-color: #575c6bdf;    
}

.image-editor .progress-circle-wrapper {
    color:rgb(243, 243, 243);
}

.image-editor .progress-circle-wrapper svg.progress-circle {
    width:140px;
}

.image-editor .progress-circle-wrapper .donezo {
    display:-webkit-flex;
    display:flex;
    width:100%;
    height:100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    color:rgb(47, 51, 83);
    color:rgb(171, 179, 196);;
}

.image-editor .progress-circle-wrapper .donezo svg {
    font-size: 26px;
}
.image-upload {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;            
}

.image-upload .header {
    display:-webkit-flex;
    display:flex;
    height:60px;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    -webkit-align-items:center;
            align-items:center;
    background-color:rgb(237, 238, 238);
    color:#000;
}

.image-upload .header h4 {
    margin:0 10px;
    display:block;
    font-size: 16px;
}

.image-upload .header .header-section {
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    background-color:#d3d3d4;
    height:100%;
    color:#090a0d;
}

.image-upload .header button.submit:hover {
    cursor: pointer;
    background-color:#436d6d;
    color:#fff;
}

.image-upload .header button.submit {
    display: block;
    height: 100%;
    padding: 0 20px;
    font-size: 16px;
    margin:0;
    border:none;
    background-color:#3f5757;
    color:#fff;
    font-weight: bold;
    text-transform:uppercase;
}

.image-upload .images {
    display:-webkit-flex;
    display:flex; 
    -webkit-flex-wrap: wrap; 
            flex-wrap: wrap;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}
.image-viewer {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    background-color:#191f1f;
    border-radius:3px;
    margin:17px;
    position: relative;
    box-shadow: 0 0 3px 1px #111212;
}

.image-viewer img {
    max-width: 300px;
    min-width: 300px;
    max-height: 250px;
    min-height: 250px;
    background-color:#00000065;
    object-fit: contain;
}

label.error {
    color:rgb(197, 132, 132);
    margin-bottom:10px;
}

.image-viewer:hover .actions {
    display:-webkit-flex;
    display:flex;
}

.image-viewer .actions {
    display:none;
    position: absolute;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    background-color: #000000aa;
    bottom:0px;
    width:300px;
    height:250px;   
    
}

.image-viewer .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    background-color: #000000aa;
}

.image-viewer .actions button {
    display:block;
    height:50px;
    border:none;
    background-color:#344141;
    border-radius:4px;
    padding:12px;
    color:#fff;
}

.image-viewer .actions button:hover {
    cursor:pointer;
    background-color:#455a5a;
}

.image-viewer input {
    background-color: #0a1111;
    color:#fff;
    padding:10px;
    border:none;
    margin:10px;
    border-radius: 3px;
}

.image-viewer h4 {
    color:#fff;
    font-weight: normal;
    text-align: center;
    margin:20px;
}

.image-viewer form {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.image-viewer {
    display:-webkit-flex;
    display:flex;    
}

.image-viewer button {
    margin:5px;
}
.image-admin {
     display:-webkit-flex;
     display:flex;
     -webkit-flex-wrap: wrap;
             flex-wrap: wrap;
     -webkit-justify-content:center;
             justify-content:center;
     -webkit-align-items:center;
             align-items:center;
}

.image-admin .image-header {
     width: 100%;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content:space-between;
             justify-content:space-between;
     -webkit-align-items:center;
             align-items:center;
     padding:15px;
     background-color:rgb(237, 238, 238);
}

.image-admin .image-header p,
.image-admin .image-header a {
     padding:0;
     margin:0;
}

.image-admin .image-header a {
     color:#185757;
     text-decoration:none;
}

.image-admin .image-header a:hover {
     color:#294646;
}

.empty {
     display:-webkit-flex;
     display:flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     -webkit-align-items:center;
             align-items:center;
     -webkit-justify-content:center;
             justify-content:center;
     padding:20px;
}

.empty p {
     color: #fff;
     font-size:18px;
     display: block;
}

.empty a {
     background-color:#455e5e;
     color: #fff;
     padding: 13px;
     display: block;
     text-decoration: none;
}

.empty a:hover {
     background-color: #668383;
     cursor: pointer;
}

.contact {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

.contact h1 {
    color: #fff;
}

.contact form {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;    
}

.contact form textarea {
    width:500px;
    min-height: 150px;
    border-radius: 3px;
    border: none;
    box-shadow: inset 0 0 4px -2px #565656;
    padding:10px;
    margin: 10px;
    box-sizing: border-box;
    font-size:14px;
}

.contact form .labels {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    margin:10px 0;
}

.contact form label {
    color:#fff;
}

.contact form input,
.contact form textarea {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: inset 0 0 4px -2px #1c2222;
}

.contact form input:active,
.contact form textarea:active {
    border:1px solid #101414;
}

.contact form button {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: 0 0 4px -2px #1c2222;
}

.contact form button:hover {
    background-color:#2b3535;
    cursor: pointer;
}

.contact form label.error {
    color:#fa5d5d;
    text-align: right;
}

@media (max-width: 827px) {
    .contact form textarea {
        width: 280px;
    }

    .contact h1 {
        font-size:24px;
    }
}
.lord {
     
}

.user-settings {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;     
    margin: 30px 0;
}

.user-settings .password-change {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;         
}

.user-settings .password-change textarea {
    width:500px;
    min-height: 150px;
    border-radius: 3px;
    border: none;
    box-shadow: inset 0 0 4px -2px #565656;
    padding:10px;
    margin: 10px;
    box-sizing: border-box;
    font-size:14px;
}

.user-settings .password-change .labels {
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    margin:10px 0;
}

.user-settings .password-change label {
    color:#fff;
    margin:10px 0;
}

.user-settings .password-change input,
.user-settings .password-change textarea {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: inset 0 0 4px -2px #1c2222;
    margin:10px 0;
}

.user-settings .password-change input:active,
.user-settings .password-change textarea:active {
    border:1px solid #101414;
}

.user-settings button {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    margin: 8px;
    box-shadow: 0 0 4px -2px #1c2222;
}

.user-settings button:hover {
    background-color:#2b3535;
    cursor: pointer;
}

.user-settings .password-change label.error {
    color:#fa5d5d;
    text-align: right;
}

.user-settings .success-message {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:center;
            justify-content:center;
    color:#fff;
}

 body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size:20px;
    line-height:28px;
}

h1,h2 {
    font-family: 'Shippori Mincho', serif;
    font-weight:normal;
}

