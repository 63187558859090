.image-editor {
    position: relative;
    margin:10px;    
    padding: 10px;    
    border-radius:3px;
    background-color:#191f1f;
}

.image-editor .error {
    position:absolute;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    border-radius:3px;
    background-color:rgb(33, 39, 54);
    z-index: 6;;
}

.CircularProgressbar-trail {
    display: none;
  }

.image-editor .error button.error-close {
    border:none;    
    display:block;
    padding:10px 20px;
    font-weight: bold;
    margin:7px;
    color:rgb(37, 37, 37);
    background-color:rgb(171, 179, 196);
    border-radius:2px;
}

.image-editor .error button.error-close:hover {
    background-color:rgb(219, 214, 214);
    color:#000;
    cursor: pointer;
}

.image-editor .error p {
    color:#fff;
}

.image-editor form .image { 
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;    
}

.image-editor form .image .title { 
    display:flex;
    align-items:center;
}

.image-editor form .image .title input { 
    display:block;
    background-color:#0d1111;
    color:#fff;
    border:none;
    padding:10px;
    margin:10px;
}

.image-editor form .image .title label { 
    display:block;
    color:rgb(219, 224, 248);
}

.image-editor form .image input {
    display:block;
    width:200px;
    box-sizing: border-box;
}

.image-editor form .image p {
    display:block;
    width: 290px;
    padding:10px;
    margin:0;    
    color:rgb(220, 225, 248);
}

.image-editor form .image img {    
    max-width: 300px;
    min-width: 300px;
    max-height: 250px;
    min-height: 250px;
    background-color:#00000065;
    object-fit: contain;
}

.image-editor.regular:hover .actions {
    display:flex;
}

.image-editor .image-holder {
    position: relative;
}

.image-editor form .actions {
    position: absolute;
    width:100%;
    height:100%;
    background-color:#000000cc;
    display:none;
    align-items:center;
    justify-content: space-around;
}

.image-editor form .actions button.action {
    width:48px;    
    height:48px;
    padding: 0px;
    border: none;
    color: #FFF;
    font-size: 18px;
    z-index: 3;
    border-radius:2px;
    top:170px;
}

.image-editor button.delete {
    background-color: rgb(219, 87, 87);
}

.image-editor button.submit {
    background-color:#344141 ;
}

.image-editor button.submit:hover {
    background-color: #4b5e5e;
    cursor: pointer;
}

.image-editor button.delete:hover {
    cursor:pointer;
    background-color:#4b5e5e;
}

.image-editor.confirm button.action {
    display:none;
}

.image-editor .confirm-delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    position: absolute;
    width:100%;
    height:100%;
    background-color:#191f1f;    
    top: 0;
    color:#fff;
    left:0;
    border-radius:3px;
    transform-origin: 60% 55%; 
    z-index: 6;
}

.image-editor .confirm-delete .options {
    display: flex;
}

.image-editor .confirm-delete .options button:hover {
    background-color:rgb(219, 214, 214);
    color:#000;
    cursor: pointer;
}

.image-editor .confirm-delete .options button {
    border:none;    
    display:block;
    padding:10px 20px;
    font-weight: bold;
    margin:7px;
    color:rgb(37, 37, 37);
    background-color:rgb(171, 179, 196);
    border-radius:2px;
}

.image-editor .confirm-delete .options button:first-child:hover {
    background-color:rgb(219, 214, 214);
    color:rgb(179, 79, 79);
}
.image-editor .confirm-delete .options button:first-child {
    background-color:rgb(179, 79, 79);
    color:rgb(41, 1, 1);
}

.image-editor form .image-submit {
    display:block;
    width: 48px;

}

.image-editor label.error-label {
    color:rgb(228, 124, 124);
    margin:0px 0 10px 0px;
}

.image-editor .progress-circle-wrapper {
    display:flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    height:100%;
    position: absolute;
    border-radius:3px;
    top:0;
    left:0;
    z-index: 6;
    background-color: #575c6bdf;    
}

.image-editor .progress-circle-wrapper {
    color:rgb(243, 243, 243);
}

.image-editor .progress-circle-wrapper svg.progress-circle {
    width:140px;
}

.image-editor .progress-circle-wrapper .donezo {
    display:flex;
    width:100%;
    height:100%;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    color:rgb(47, 51, 83);
    color:rgb(171, 179, 196);;
}

.image-editor .progress-circle-wrapper .donezo svg {
    font-size: 26px;
}