.portfolio {
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    position: relative;
}

.portfolio.loading {
    width:100%;
    height:100%;
    overflow: hidden;
    justify-content:center;
    align-items:center;
}

.portfolio .entry {
    width:400px;
    display:flex;
    position:relative;
    margin:45px 0;
    z-index:2;
}

.portfolio .hidden {
    display:none !important;
}

.portfolio .entry .image-wrapper {
    display:flex;
    width:400px;
    max-height:260px;    
    border:4px solid #FFF;
    box-sizing:border-box;
    position:relative;    
    overflow:hidden;
}

.portfolio .portfolio-title button.no-style:hover {
    background:none;
}

.portfolio .portfolio-title p {
    margin: 0 18px;
    font-size:15px;
    flex:1;
    text-align:right;
}

.portfolio .portfolio-title button.no-style {
    background:none;
    border:none;
    border-right: 2px solid rgb(129, 129, 129);
    color:#000;
    margin:10px;
    padding:0 5px 0 0px;
    border-radius:0;
}

.portfolio .entry .image-wrapper {
    opacity: 0;
}

.portfolio .entry .image-wrapper:first-child,
.portfolio .entry .image-wrapper:nth-child(3),
.portfolio .entry .image-wrapper:nth-child(2) {
    z-index:2;
    opacity: 1;
}

.portfolio .entry .image-wrapper:not(:first-child) {
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    transition:transform 0.5s;
}

.portfolio .entry:hover {
    cursor:pointer;
}
.portfolio .entry:hover .info p {
    display:block;
}

.portfolio .entry:hover .image-wrapper:nth-child(3) {
    transform:rotate(7deg) translate(10px,20px);
}

.portfolio .entry:hover .image-wrapper:nth-child(2) {
    transform:rotate(-8deg) translate(-10px,-20px);
}

.portfolio .entry .image-wrapper a.image {
    width:100%;
    height:100%;
}

.portfolio .entry .image-wrapper a.image img {
    width:100%;
    height:100%;
    object-fit:cover;
    z-index:2;
    position:relative;
}

.portfolio .entry .info {
    position:absolute;
    top:0;
    left:5%;
    width:90%;
    height:100%;
    z-index:2;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.portfolio .entry .info h2 {
    color:#fff;
    margin:0;
}

.portfolio .entry .info-content {
    padding:20px 40px;;
    background-color:rgba(0,0,0,0.65);
    text-align:center;
}

.portfolio .entry .info p {
    color:#fff;
    padding:0;
    margin:0;
    font-size:14px;
    display:none;
}

.portfolio .portfolio-single {
    display:flex;
    flex-wrap:wrap;
    padding:20px;
    justify-content:center;
}

.portfolio-single .image-wrapper a.image:hover .info {
    display:flex;
    
}

.portfolio-single .info {
    display:none;
}
    
.portfolio-single .image-wrapper {
    max-width:400px;
    max-height:320px;
    margin:10px;
    position:relative;
    display:flex;
}

.portfolio-single .image-wrapper a.image {
    display:block;
    box-sizing: border-box;
    position: relative;
    border:4px solid #FFF;
}

.portfolio-single a.image .info {
    display:none;
    justify-content:center;
    align-items:center;
    position:absolute;
    bottom:0;
    right:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.4);    
}

.portfolio-single a.image .info p {
    color:#fff;
    text-align:right;
    padding:0;
    margin:10px;
    font-size:16px;
}

.portfolio-single img {
    width:100%;
    height:100%;
    object-fit:cover;
    box-sizing:border-box;
}

.portfolio-slide {
    height:100vh;
    position:relative;
    display:flex;
    flex:1;
    justify-content:center;
    align-items:center;
}

.portfolio-slide .control {
    min-width:50px;
    width: 50px;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    top: 0;
    left:50px;
    z-index:5
}

.portfolio-slide .control:last-child {
    right:50px;
    left:unset;    
}

.portfolio-slide .control button:hover {
    cursor:pointer;
    color:#2b3535;
	
}
.portfolio-slide .control button {
    display:block;
    font-size:36px;
    color:#191f1f;
    border:none;
    background:none;
}

.portfolio-slide .title {
    position:absolute;
    background-color:#fff;
    top:0;
    left:-50px;
    display:flex;    
    align-items:center;
    z-index:5;
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71); 
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
}

.portfolio-slide .title a {
    width:50px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-right:1px solid #000;    
}

.portfolio-slide .title a svg {
    font-size:18px;    
}

.portfolio-slide .title span svg {
    font-size:16px;
}

.portfolio-slide .title span {
    margin:19px;
    display:block;
    display:flex;
    font-size:20px;
    align-items:center;
}


.portfolio-slide .image-holder {    
    height:100vh;
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
}


.portfolio-slide .image-holder .image-slide img.iiz__img {
    object-fit:contain;
    max-height:100vh;
    overflow:hidden;
}


.fade-enter {
    transform: scale(0);
}

.fade-enter.fade-enter-active {
    transform: scale(1);
    transition: transform 0.3s ease-in;
}

.portfolio .new-entry.on {
    background-color:#191f1f;
}


.portfolio .portfolio-title {
    background-color:#fff;
    width:100%;
    height:60px;
    padding:10px 40px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
}

.portfolio input {
    padding:10px;
    border:none;
    background-color:rgb(223, 223, 223);
    border-radius: 3px;
    margin:0 5px 0 0;
}


.portfolio .portfolio-title button:hover {
    background-color:#3f4e4e;
    cursor: pointer;
}

.portfolio .portfolio-title button {
    border:none;
    background-color: #191f1f;
    color:#fff;
    border-radius: 3px;
    padding:10px;
    margin: 0 10px;
}

.portfolio .portfolio-title h4 {
    margin:0;    
}

.portfolio .new-entry {    
    width:400px;
    height:300px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#fff;
}

.portfolio .new-entry button {
    background-color:#232b2b;
    margin: 10px;
    color: #fff;
    border:none;
    padding:8px 18px;
    border-radius:3px;
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
}

.portfolio .new-entry button:hover {
    cursor: pointer;
    background-color:#344141;
    
}
.portfolio .new-entry input {
    padding:10px;
    width:200px;
    background-color:#0e1313;
    border:none;
    color:#fff;
}



.portfolio .new-entry label {
    text-align:left;
    width:220px;
    margin: 10px;
}

.portfolio .new-entry.off button {
    background-color:#3f4e4e;
}

.portfolio .new-entry.off button:hover {
    background-color:#4b5e5e;
}

.portfolio .entry.empty {
    background-color: #191f1f;
    height:240px;
}



.portfolio .action-popper {
    position: absolute;
    top: 20px;
    right:20px;
}

.portfolio .actions-floater .deleter {
    width:100%;
    display:flex;
    flex-direction:column;
    background-color:#220a09;;
}

.portfolio .actions-floater {
    position: absolute;
    right:0px;
    top:0px;    
    width:150px;
    background-color: #191f1f;
    display: flex;
    flex-direction:column;
}
.portfolio button.action-floater {
    margin:10px;
    border:none;
    padding:12px;
    background-color:#344141;
    color:#fff;
    border-radius:3px;
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
}

.portfolio button.action-floater:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

@media (max-width:827px) {
    .portfolio .entry {
        width:230px;
    }

    .portfolio .entry .image-wrapper {
        width:230px;
    }

    .portfolio .portfolio-title {
        padding:10px;
    }
}