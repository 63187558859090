.contact {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.contact h1 {
    color: #fff;
}

.contact form {
    display:flex;
    flex-direction:column;    
}

.contact form textarea {
    width:500px;
    min-height: 150px;
    border-radius: 3px;
    border: none;
    box-shadow: inset 0 0 4px -2px #565656;
    padding:10px;
    margin: 10px;
    box-sizing: border-box;
    font-size:14px;
}

.contact form .labels {
    display:flex;
    justify-content:space-between;
    margin:10px 0;
}

.contact form label {
    color:#fff;
}

.contact form input,
.contact form textarea {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: inset 0 0 4px -2px #1c2222;
}

.contact form input:active,
.contact form textarea:active {
    border:1px solid #101414;
}

.contact form button {
    border:none;
    background-color:#191f1f;
    color: #fff;
    padding:14px;
    box-shadow: 0 0 4px -2px #1c2222;
}

.contact form button:hover {
    background-color:#2b3535;
    cursor: pointer;
}

.contact form label.error {
    color:#fa5d5d;
    text-align: right;
}

@media (max-width: 827px) {
    .contact form textarea {
        width: 280px;
    }

    .contact h1 {
        font-size:24px;
    }
}