.image-viewer {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background-color:#191f1f;
    border-radius:3px;
    margin:17px;
    position: relative;
    box-shadow: 0 0 3px 1px #111212;
}

.image-viewer img {
    max-width: 300px;
    min-width: 300px;
    max-height: 250px;
    min-height: 250px;
    background-color:#00000065;
    object-fit: contain;
}

label.error {
    color:rgb(197, 132, 132);
    margin-bottom:10px;
}

.image-viewer:hover .actions {
    display:flex;
}

.image-viewer .actions {
    display:none;
    position: absolute;
    justify-content:center;
    align-items:center;
    background-color: #000000aa;
    bottom:0px;
    width:300px;
    height:250px;   
    
}

.image-viewer .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: #000000aa;
}

.image-viewer .actions button {
    display:block;
    height:50px;
    border:none;
    background-color:#344141;
    border-radius:4px;
    padding:12px;
    color:#fff;
}

.image-viewer .actions button:hover {
    cursor:pointer;
    background-color:#455a5a;
}

.image-viewer input {
    background-color: #0a1111;
    color:#fff;
    padding:10px;
    border:none;
    margin:10px;
    border-radius: 3px;
}

.image-viewer h4 {
    color:#fff;
    font-weight: normal;
    text-align: center;
    margin:20px;
}

.image-viewer form {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.image-viewer {
    display:flex;    
}

.image-viewer button {
    margin:5px;
}