body {
    width:100%;
    height:100%;
}

#root {
    
}

.coming-soon {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:#fefefe;
    z-index:10;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:16px;
}

.coming-soon p {
    display:block;
}

.site-content {
    position:relative;
    display:flex;
    background-color: 	#232b2b;
}

.main-content {
    flex:1;
    position: relative;
}

.site-content .nav {
    position:sticky;
    top:0;
    max-height:100vh;
    min-height:100vh;
}

.loading {
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}