.image-upload {
    display:flex;
    flex-direction:column;            
}

.image-upload .header {
    display:flex;
    height:60px;
    justify-content:space-between;
    align-items:center;
    background-color:rgb(237, 238, 238);
    color:#000;
}

.image-upload .header h4 {
    margin:0 10px;
    display:block;
    font-size: 16px;
}

.image-upload .header .header-section {
    display:flex;
    align-items:center;
    background-color:#d3d3d4;
    height:100%;
    color:#090a0d;
}

.image-upload .header button.submit:hover {
    cursor: pointer;
    background-color:#436d6d;
    color:#fff;
}

.image-upload .header button.submit {
    display: block;
    height: 100%;
    padding: 0 20px;
    font-size: 16px;
    margin:0;
    border:none;
    background-color:#3f5757;
    color:#fff;
    font-weight: bold;
    text-transform:uppercase;
}

.image-upload .images {
    display:flex; 
    flex-wrap: wrap;
    justify-content:center;
    align-items:center;
}