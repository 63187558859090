.media-slides {
    display:flex;
    position:relative;
}

.media-slides .media {
    position:absolute;
    object-fit:cover;
    object-position:10%;
    margin:0 auto;
    width:100%;
    height:100%;
}

.loading-images {
    height:400px;
    display:flex;
    justify-content:center;
    align-items:center;
}
