.navigation {
    background-color:#FFFFFF;
    padding:20px 50px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:space-between;
    min-height:100%;
    height:auto;
    box-sizing:border-box;
    width:300px;
    z-index:10;
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71); 
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.71);
    transition:width 0.6s;
}

.navigation button.link {
    background:none;
    border:none;
    color:#000;
    font-size:18px;
}

.navigation.collapsed {
    width:50px;
    padding:20px;
    align-items:center;
}

.navigation.open {
    position: fixed !important;
    top:0;
    left:0;
    width: 100vw;
}

.navigation button.close:hover {
    cursor: pointer;
}

.navigation button.close {
    position: absolute;
    top:0;
    right:0;
    padding:35px;
    background:none;
    border:none;
    color:#000;
}

.navigation.collapsed .links {
    align-self:center;
}

.navigation.collapsed .links ul.nav-list li a span {
    opacity:0;
    width:0;
    transform:scale(0);
}

.navigation.collapsed .logo {
    width:20px;    
    height:42px
}

.navigation.collapsed .links ul li {
    margin:10px 0;
}

.navigation.collapsed .links ul li a {
    padding:0;
}

.navigation.collapsed .links ul li a svg {
    margin:0;
}

.navigation.collapsed .social ul.social-list {
    flex-direction:column;
}

.navigation.collapsed .social ul.social-list li {
    margin:6px 0;
}

.navigation.collapsed .social ul.social-list li a,
.navigation.collapsed .social ul.social-list li button {
    padding:0;    
}

.navigation .logo {
    width:200px;
    transition:width 0.6s ease, height 0.6s ease;
    padding:0;
}

.navigation .logo img {
    max-width:100%;
    max-height: 42px;
}

.navigation .links {
    align-self:flex-start;
}

.navigation .links ul {
    list-style:none;
    margin:0;
    padding:0;
    display:flex;
    flex-direction:column;
}

.navigation .links ul li {
    padding:0;
    margin:5px;
}

.navigation .links ul li a {
    text-decoration:none;
    position:relative;
    padding:5px;
    color:#111;
    font-size:16px;
    display:flex;
    align-items:center;
}

.navigation .links ul li a span {
    position:relative;
    display:block;
    opacity:1;
    transform:scale(1);
    transition: transform 0.3s ease-out, opacity 0.3s ease;
}

.navigation .links ul li a svg {
    font-size:22px;
    margin:0 15px;
    display:block;
}

.navigation .links ul li a span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}

.navigation .links ul li a.active svg,
.navigation .links ul li a:hover svg {
    color: #0a6868;
}

.navigation .links ul li a.active span::before {
    visibility: visible;
    transform: scaleX(1);
}

.navigation .links ul li a:hover span::before {
  visibility: visible;
  transform: scaleX(1);
}

.navigation .social ul.social-list {
    list-style:none;
    margin:0;
    padding:0;
    display:flex;
}

.navigation .social ul.social-list li a,
.navigation .social ul.social-list li button {
    font-size:18px;
    padding:0 10px;
    display:block;
    background:none;
    border:none;
    color:#000;
}

.navigation .social ul.social-list li a:hover,
.navigation .social ul.social-list li button:hover {
    cursor: pointer;
    color:rgb(37, 103, 202);
}
