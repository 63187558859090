.experiences {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}

.experiences .header {
    align-self:flex-start;
    margin:3px 25px;
}

.experiences .header h1 {
    margin:0;
    padding:0;
}

.experiences .content {
    display:flex;
}
