.experience {
    background-color:#fffdfd;
    padding:5px;
    margin:10px;
    box-sizing:content-box;
    -webkit-box-shadow: 0px 0px 2px -1px #250f03; 
    box-shadow: 0px 0px 2px -1px #250f03;
    border-radius:5px;
    width:300px;
    display:flex;
    flex-direction:column;
}

.experience .media-preview {
    width:300px;
    height:400px;
    overflow:hidden;
}

.experience .info {
    padding:5px;
    display:flex;
    flex:1;
    flex-direction:column;
    justify-content:space-between;
}
.experience .info h3 {
    font-size:20px;
    margin:5px 0;
}

.experience .info p {
    font-size:14px;
    margin:0px 0;
    line-height:18px;
}

.experience .info .stats {
    display:flex;
    border-top:1px solid #250f03;
    padding:3px 0;
    margin-top:5px;
    box-sizing:border-box;
}

.experience .info .stats .stat {
    display:flex;
    align-items:center;
    padding: 3px 5px;
    padding-left:0;
}

.experience .info .stats .stat span {
    display:block;
    padding: 0 5px;
}

.experience .info .stats .stat svg {
    font-size:16px;
    color:#8f3821;
}
