.package-wrapper {
    width:100%;
    height:300px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    align-self: flex-start;
    overflow:unset;
    position: relative;
}

.edit-actions {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;    
    width:100%;
    display: flex;
    justify-content:space-around;
    margin:12px 0;
}


.edit-actions.image-change {
    bottom:0;
    left:0;
    top:unset;
    width: 70px;
}

.edit-actions.sticky {
    margin:0;
    position:fixed;
    top:52.4px;
    z-index: 3;    
    width:auto;
    height:auto;
    flex-direction: column;
    justify-content:space-between;
    align-items:flex-end;
}

.edit-actions button.edit-package {
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border:none;
    height:50px;
    width:50px;
    color:#fff;
    z-index:4;
    position: relative;
}

.edit-actions .edit-action-group {
    display: flex;
    flex-direction: column;
}

.edit-actions .edit-action-group button.edit-package {
    width:auto;
}

.edit-actions button.edit-package.label span {
    margin-left:50px;
}


.package-wrapper .package-section .edit-actions .edit-action-group {
    flex-direction:row;
}

.package-wrapper .package-section .edit-actions .edit-action-group button.edit-package {
    margin:0 20px;
}

.package-wrapper .package-section .confirm-delete {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#000d;
    z-index:3;
}

.package-wrapper .package-section .confirm-delete .confirm-delete-actions {
    display: flex;
    justify-content:space-between;    
}   

.package-wrapper .package-section .confirm-delete .confirm-delete-actions button.confirm-delete-action:hover {
    cursor: pointer;
    background-color:rgb(79, 109, 175);
}
.package-wrapper .package-section .confirm-delete .confirm-delete-actions button.confirm-delete-action {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background-color:rgb(53, 61, 97);
    border:none;
    color:#fff;
    width:140px;
    height:140px;
    border-radius:100px;
    margin:0 10px;
}

.package-wrapper .package-section .confirm-delete .confirm-delete-actions button.confirm-delete-action svg {
    font-size: 26px;
    margin:5px;
}

.edit-actions button.edit-package:hover svg:not(:only-child) {   
    border-right:3px solid #374642;
}

.edit-actions button.edit-package svg:not(:only-child) {   
    border-right:3px solid #51615d;
    width:50px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top:0;
    padding:10px;
    height:100%;
}

.edit-actions button.edit-package:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

.edit-actions button.edit-package.disabled {
    color:#818181;
}

.edit-actions button.edit-package.disabled:hover {
    cursor: default;
    background-color:#344141;
}

.package-section button.new-section:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

.package-section button.new-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    font-size:20px;
    width:200px;
    height:200px;
    color:#fff;
    border-radius:100px;
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border: none;
}

.package-section button.new-section  svg {
    font-size: 36px;
}

.package-handle {
    width: 100%;
    height:100%;
    text-decoration: none;
}

.package-wrapper.entry .package-handle:hover {
    cursor: pointer;
}

.package {
    position:relative;
    width: 100%;
    height: 300px;
    display: flex;    
    justify-content:center;
    align-items:center;
    flex-direction:row;
    padding:50px;
    box-sizing: border-box;
    transition:height linear 500ms;
}

.package .package-info {    
    display: flex;
    flex-direction: column;
    justify-content:center;
    max-width: 500px;
    min-width: 300px;
    max-height: 230px;
    z-index: 2;
    padding:30px;
    color:#fff;
    box-sizing: border-box;
    position: relative;
}

.package .package-info h1 {
    font-size:26px;
}

.package .package-info input {    
    background-color: #0005;
    border:1px solid #1a1b29ee;
    color:#fff;
    padding:16px;
    font-size: 26px;
    font-family: 'Shippori Mincho', serif;
    box-sizing: border-box;    
}


.package .package-info button.package-info-action {
    position:absolute;
    top:-20px;
    right:-20px;
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border:none;
    width:40px;
    height:40px;
    color:#fff;
    border-radius:70px;

    display: flex;
    justify-content:center;
    align-items:center;
    z-index: 5;
}

.package .package-info button.package-info-action:hover {    
    cursor: pointer;
    background-color:#4b5e5e;
}

.move-wrapper {    
    position:absolute;
    display: flex;
    align-items: center;
    height: 100%;    
}

button.move {        
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    border:none;
    width:40px;
    height:40px;
    color:#fff;
    border-radius:70px;
    display: flex;
    justify-content:center;
    align-items:center;
}

button.move:hover {        
    cursor: pointer;
    background-color:#4b5e5e;
    color:rgb(3, 18, 54);
}

.move-wrapper.left {
    left: -50px;
    top:0;
}

.move-wrapper.right {
    right: -50px;
    top:0;
}

.package-wrapper .package-background .overlay {
    background-color:#191f1f;
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
}

.package-wrapper.single .package-background .overlay{
    display:none;
}

.package .explore {
    z-index: 2;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content:center;
    color: #fff;
    font-size: 24px;
    height: 100%;
    width: 50%;
}

.package-wrapper.single .package .explore {
    display: none;
}

.package .explore svg {
    font-size:38px;
}

.package-wrapper .package-background {
    position: absolute;
    overflow:hidden;
    width:100%;
    height:100%;
}
.package-wrapper.single {
    height:auto;
}

.package-wrapper.single .package {
    height:230px;
}

.package-wrapper .package-background img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.sun-editor {
    background-color: #0005;
    border:1px solid #1a1b29ee;
    box-sizing: border-box;
    margin-top: 16px;
}

.package-wrapper.edit .package .package-info {
    padding: 14px;
    max-height: 240px;
}

.package-wrapper.edit .package {
    height:300px;
}

.package-wrapper .contents {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.package-wrapper .contents .package-section {
    display: flex;
    align-items:center;
    justify-content:space-around;
    height:500px;
    width: 100%;
    background-color:#232b2b;   
    color: #fff; 
    position: relative;
}

.package-wrapper .contents .package-section .divider {
    position: absolute;
    width: 100%;    
    height: 100%;
    top:0;
    left:0;
    display:flex;
    justify-content: center;
    align-items:center;
}

.package-wrapper .contents .package-section.left {
    justify-content: flex-start;
    padding:40px;
    box-sizing: border-box;
}

.package-wrapper .contents .package-section.middle {
    justify-content: center;
    padding:40px;
    box-sizing: border-box;
}

.package-wrapper .contents .package-section.right {
    justify-content:flex-end;
    padding:40px;
    box-sizing: border-box;
}

.package-wrapper .contents .package-section .actions-section {
    position:absolute;
    bottom:-65px;    
    left:0;
    width: 100%;
    display: flex;    
    justify-content:space-around;     

}
.package-wrapper .contents .package-section .package-text .sun-editor {
    margin:0;
}

.package-wrapper .contents .package-section .package-text.with-background {
    background-color:rgba(50, 51, 65, 0.933);
    position: relative;
}

.package-wrapper .contents .package-section .image-background .actions-section {
    margin-bottom: 70px;
}

.package-wrapper .contents .package-section .actions-section .action-remove:hover {   
    background-color:#4b5e5e;
    cursor: pointer;
}

.package-wrapper .contents .package-section .actions-section .action-remove {   
    border:none;
    font-size: 24px;
    background-color:#344141;
    box-shadow: 0 0 4px -2px rgb(3,4,11);
    color:#fff;
    width:60px;
    height:60px;
    border-radius: 120px;
}

.package-wrapper .contents .package-section .divider button.divider-switch:hover {
    color: #5b7575;
    cursor: pointer;
}

.package-wrapper .contents .package-section .divider button.divider-switch {
    position: absolute;
    background-color:#232b2b;
    border:none;
    color:#fff;
    font-size:42px;
}

.package-wrapper .contents .package-section .divider .divider-line {
    height: 60%;
    width: 1px;
    background-color:#344141;
}

.package-wrapper .contents .package-section:nth-child(odd) .divider button.divider-switch,
.package-wrapper .contents .package-section:nth-child(odd)   {
    background-color:#191f1f;
}

.package-wrapper .contents .package-section .package-text {
    padding:26px;
    max-width: 400px;
    max-height:400px;
    font-size:20px;
    line-height: 30px;
    z-index: 1;
    text-align:center;
    flex:1;
    position:relative;
}

.package-wrapper .remove-confirm-actions {
    display: flex;
}

.package-wrapper .remove-confirm-action svg {
    font-size:28px;
    margin:4px 0;
}

.package-wrapper .remove-confirm-action:hover {
    cursor: pointer;
    background-color:#4b5e5e;
}

.package-wrapper .remove-confirm-action {
    border:none;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background-color:#344141;
    color: #fff;
    width:120px;
    height:120px;
    border-radius:60px;
    margin:0 20px
}

.package-wrapper .remove-confirm {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    background-color:#222e;
    color:#fff;
    z-index:5;
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}
.package-wrapper .contents .package-section.reverse {
    flex-direction:row-reverse;
}

.package-wrapper .contents .package-section .package-image {
    max-width: 500px;
    max-height: 400px;
    flex:1;
    position: relative;
    display: flex;
    justify-content: center;
}

.package-wrapper .contents .package-section .package-image.image-background {
    position:absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;    
}

.package-wrapper .contents .package-section .package-image.image-background img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit:cover;
}

.package-wrapper .contents .package-section .package-image img {
    max-width: 500px;
    max-height:340px;    
    display: flex;        
    object-fit: contain;
    box-shadow: 0 0 4px -1px #000;
}

.package-wrapper .contents .package-section .package-image.back {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    max-width: 100%;    
    z-index:0;
}

.package-wrapper .contents .package-section .package-image.back img {
    object-fit: cover;
    box-shadow: none;
}

@media (max-width:827px) {
    .package-wrapper .package-handle .filler {
        display:none !important;
    }

    .package .package-info {
        min-width: 200px;
        max-width: 260px;
    }

    .package-wrapper .contents .package-section {
        flex-direction: column;
    }

    .package-wrapper .contents .package-section .package-image {
        max-width: 300px;
    }

    .package-wrapper .contents .package-section .package-image img {
        max-width: 300px;
        width:300px;
        height:auto
    }
}