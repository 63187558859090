.packages {
     display: flex;
     justify-content:center;
     align-items:center;
     flex-direction: column;
     width:100%;
}

.packages.loading {
     height:100%;
     overflow:hidden;
}

.packages button.create-package {
     display:flex;
     flex-direction: column;
     border:none;
     background-color:#191f1f;
     color:#fff;
     width:160px;
     height:160px;
     margin:120px 0;
     justify-content:center;
     align-items:center;     
     border-radius: 80px;
}

.packages button.create-package svg {
     font-size:26px;
     margin:10px 0;
}

.packages button.create-package:hover {
     cursor: pointer;
     background-color:#344141;
}